<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12">
        <div class="ml-2 mb-3">
          <div class="row">
            <div class="col-md-5">
              <div class="card" v-loading="loading.detail">
                <div class="card-body">
                  <h5 class="text-center mr-4">
                    <a class="btn btn-icon rounded-circle" href="#" title="Back" @click="back">
                      <FeatherIcon type="arrow-left" />
                    </a>
                    Detail Information
                  </h5>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mt-4 ml-3">
                        <hr>
                        <p class="font-weight-bold">Name</p>
                        <p class="text-muted mb-3">{{ leads.name }}</p>
                        <p class="font-weight-bold">Contact</p>
                        <p class="text-muted mb-3">{{ leads.contact?.display_name || leads.contact?.name }} ({{ leads.contact?.phone_number }})</p>
                        <p class="font-weight-bold">Amount</p>
                        <p class="text-muted mb-3">Rp. {{ leads.amount_str }}</p>
                        <p class="font-weight-bold">Due Date</p>
                        <p class="text-muted">{{ leads.due_date_str }}</p>
                        <b-badge v-if="!leads.status" :variant="leads.color_date" class="p-1">{{ leads.label_date }}</b-badge>
                        <p class="font-weight-bold mt-3">Assigned to</p>
                        <p class="text-muted mb-3">{{ leads.user?.name }} ({{ leads.user?.email }})</p>
                        <p class="font-weight-bold mt-3">Team</p>
                        <p class="text-muted mb-3">{{ leads.participant }}</p>
                        <p class="font-weight-bold">Note</p>
                        <p class="text-muted mb-1">{{ leads.description || '-' }}</p>
                        <div v-if="leads.status">
                          <p class="font-weight-bold">Status</p>
                          <p :class="leads.status_color"><b>{{ leads.status.toUpperCase() }}</b></p>
                          <p class="text-muted">{{ leads.completed_at_str }}</p>
                          <p class="font-weight-bold mt-3" v-if="leads.status === 'lost'">Reason</p>
                          <p class="text-muted mb-3" v-if="leads.status === 'lost'">{{ leads.reason == 'Others' ? leads.reason_detail : leads.reason }}</p>
                        </div>
                        <div v-else>
                          <h5><b-badge variant="primary" class="p-1 mb-3 cursor-pointer" @click="showEditNote">Edit Notes</b-badge></h5>
                          <el-form class="full-width" v-if="show.edit_note">
                            <el-form-item>
                              <el-input type="textarea" rows="5" placeholder="Change your notes here" v-model="form.description"></el-input>
                            </el-form-item>
                            <el-form-item v-if="form.description">
                              <el-button type="primary" class="float-right" size="small" @click="updateLeads()">Update</el-button>
                            </el-form-item>
                          </el-form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <div class="card">
                <div class="card-body">
                  <h5 class="text-center">History</h5>
                  <el-tabs type="card" class="mt-4">
                    <el-tab-pane label="Conversations" v-loading="loading.conversation">
                      <div v-if="show.conversation">
                        <div class="row mt-2 ml-2 chat-wrapper" style="height: auto;">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="row position-relative">
                              <div class="chat-content show" :class="showDetailConversation ? 'col-lg-8': 'col-lg-12'" style="overflow-y: auto; max-height: 550px; height: auto;">
                                <div class="chat-header border-bottom d-flex justify-content-between">
                                  <div class="align-items-center mb-2">
                                    <p><b>#{{ conversation.code }}</b></p>
                                    <p class="text-muted tx-13">{{ conversation.contactName }}, {{ conversation.phoneNumber }}</p>
                                  </div>
                                  <div class="d-flex align-items-center">
                                    <b-badge variant="primary" class="p-1">{{ upperCaseFirst(conversation.status) }}</b-badge>
                                    <b-button v-if="!this.can_reply_message && ['open','handling'].includes(conversation.status)" size="sm" variant="outline-primary" class="ml-2" @click="() => { showModalReachTicket = true; }">Reach Ticket</b-button>
                                    <b-button v-if="this.can_reply_message && ['open','handling'].includes(conversation.status)" size="sm" variant="outline-primary" class="ml-2" @click="toChat()">Live Chat</b-button>
                                    <el-tooltip class="item" effect="dark" content="Chat Information" placement="bottom">
                                      <a class="btn btn-icon rounded-circle" href="#" @click="handlerShowDetailConvo(leads.contact_id)" title="Chat Info">
                                        <FeatherIcon type="info" />
                                      </a>
                                    </el-tooltip>
                                  </div>
                                </div>
                                <div class="chat-body">
                                  <ul class="messages">
                                    <li class="message-item system" v-if="!max_page.messages">
                                      <div class="content">
                                        <div class="message">
                                          <div v-loading="loading.moreMessages" class="bubble" style="background: #fff !important; color: #000 !important;">
                                            <a @click="loadMoreMessages" href="#">Load more</a>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      class="message-item"
                                      v-for="(message, i) in formatted_messages"
                                      :key="i"
                                      :class="message.formattedType">
                                      <div v-if="message.type === 'in'" class="identicons" v-html="identicons(conversation.id)"/>
                                      <div class="content" :id="'message-' + message.id" :style="isFromAgent(message) ? 'margin-right: 15px' : ''">
                                        <div class="message" :class="{ 'has-quote': message.context && message.context.body }">
                                          <ul class="message-action" :style="message.formattedType=='me'?'flex-direction: row-reverse;':''">
                                            <li>
                                              <div class="bubble">
                                                <div v-if="message.context && message.context.body" class="quoted-message cursor-pointer" @click="goToQuotedMessage(message.context.id, message.context)">
                                                  <b>{{ message.context.type === 'out' ? $t('general.you') : message.context.contact.name }}</b><br/>
                                                  <message-item :is_context="true" :message="message.context"/>
                                                </div>
                                                <message-item :message="message"/>
                                              </div>
                                            </li>
                                          </ul>
                                          <span>
                                            <i :class="[message.channel_icon, message.channel_color]"/>
                                            {{ message.formattedTime }}
                                            <i :class="{
                                              'status-read': message.status === 'read',
                                              'mdi-check-all': ['sent', 'delivered', 'read'].includes(message.status),
                                              'mdi-clock': message.status == 'pending',
                                              'mdi-close-circle-outline': message.status === 'failed',
                                              'text-danger': message.status === 'failed'
                                              }" v-if="message.type == 'out'" class="mdi"/>
                                          </span>
                                          <span v-if="message.type === 'out'">{{ isFromAgent(message) ? message.user.name : '' }}</span>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div :class="showDetailConversation ? 'col-lg-4' : 'd-none'" class="chat-aside border-lg-left fade-in" style="overflow-y: auto; max-height: 550px; height: auto;">
                                <div class="chat-header border-bottom pb-2">
                                  <div class="d-flex justify-content-between">
                                    <div class="d-flex align-items-center">
                                      <div class="identicons" v-html="identicons(conversation.id)"/>
                                      <p class="text-muted tx-13">{{ conversation.contactName }}, {{ conversation.phoneNumber }}</p>
                                    </div>
                                    <div class="d-flex align-items-center">
                                      <a class="btn btn-icon rounded-circle" href="#" @click="showDetailConversation = !showDetailConversation" title="Chat Info">
                                        <font-awesome icon="chevron-right"/>
                                      </a>
                                    </div>
                                  </div>
                                  <div class="mt-2">
                                    <!-- <p><font-awesome icon="id-card" size="xs"/> <b>{{ conversation.contactName }}</b></p> -->
                                    <!-- <p v-for="(item, index) in conversation.contacts" :key="index">
                                      <span :class="item.color"><i :class="item.icon"></i></span>&nbsp;
                                      <span v-if="['whatsapp', 'phone_call'].includes(item.channel)">{{ item.phone_number }}</span>
                                      <span v-if="['instagram', 'fb_messenger'].includes(item.channel)">{{ item.original_name || '' }}</span>
                                      <span v-if="item.channel == 'webchat'">(Web Chat)</span>
                                    </p> -->
                                  </div>
                                </div>
                                <div class="d-flex justify-content-between border-bottom mt-3 pb-2">
                                  <h6>{{ $t('conversations.history') }}</h6>
                                </div>
                                <div>
                                  <div v-loading="loading.history" style="overflow-y: auto;">
                                    <ul class="list-unstyled chat-list mt-2">
                                      <li
                                        class="chat-item pr-2 mt-2"
                                        v-for="(convo, i) in conversationHistoryList"
                                        :key="i">
                                        <a href="javascript:;" class="d-flex align-items-center" @click="toConversation(convo.code, convo.id, 'resolved')">
                                          <div class="identicons" v-html="identicons(convo.id)" />
                                          <div class="flex-grow border-bottom">
                                            <div class="d-flex justify-content-between align-items-center">
                                              <p class="text-body font-weight-bold">#{{ convo.code }}</p>
                                            </div>
                                            <div class="mb-2">
                                              <p class="text-muted tx-10 preview-chat">
                                                {{ convo.formattedTime }}
                                              </p>
                                            </div>
                                          </div>
                                        </a>
                                      </li>
                                    </ul>
                                    <div v-loading="loading.load_more" v-if="isMoreResultHistory" align="center">
                                      <a href="#" @click="infiniteHandler">Load more</a>
                                    </div>
                                    <div v-if="!isMoreResultHistory" align="center">
                                      <p>No more previous conversations</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12" v-else>
                        <el-empty description="You have no conversation active. Let's start conversation!">
                          <b-button variant="primary" @click="() => { showModalStartTicket = true; }">Let's chat!</b-button>
                        </el-empty>
                      </div>
                      <!-- <el-empty class="text-center" v-else description="You have no conversation open"></el-empty> -->
                      <convo-reach-ticket
                        :show="showModalReachTicket"
                        :conversation_id="conversation.id"
                        @onClose="showModalReachTicket = false"
                        @resetChat="loadDetail()"
                      />
                      <convo-start-ticket
                        :show="showModalStartTicket"
                        :channel_id="channel?._id"
                        :contact_id="leads.contact_id"
                        @onClose="showModalStartTicket = false"
                        @resetChat="loadDetail()"
                      />
                    </el-tab-pane>
                    <el-tab-pane label="Logs" v-loading="loading.log">
                      <div class="row mt-2 mr-1 chat-wrapper" style="height: auto !important">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="row position-relative">
                            <div class="chat-content">
                              <div class="chat-body">
                                <el-timeline>
                                  <el-timeline-item
                                    v-for="log in logs" :key="log.id"
                                    :timestamp="log.description_added">
                                    {{ log.description }}
                                  </el-timeline-item>
                                </el-timeline>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="Schedule" v-loading="loading.schedule">
                      <div class="row mt-2 mr-1 chat-wrapper" style="height: auto !important">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="row position-relative">
                            <div class="chat-content">
                              <div class="chat-body">
                                <el-timeline>
                                  <el-timeline-item
                                    v-for="sch in schedules" :key="sch.id"
                                    :timestamp="sch.schedule_at_str">
                                    {{ sch.name }}{{ sch.description ? ' : '+sch.description : '' }}
                                  </el-timeline-item>
                                </el-timeline>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import PerfectScrollbar from 'perfect-scrollbar';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import popupErrorMessages from '@/library/popup-error-messages';
import { toSvg } from 'jdenticon';
import MessageItem from '../../components/MessageItem.vue';
import ConvoReachTicket from '../../components/conversations/ReachTicket.vue';
import ConvoStartTicket from '../../components/conversations/StartTicket.vue';
import leadsAPI from '../../../api/leads';
import conversationsAPI from '../../../api/conversations';

export default {
  name: 'LeadsCard',
  metaInfo() {
    return {
      title: 'Leads Detail',
    };
  },
  components: {
    MessageItem,
    ConvoReachTicket,
    ConvoStartTicket,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        description: {
          required,
        },
      },
    };
  },
  data() {
    return {
      loading: {
        detail: false,
        log: true,
        conversation: false,
        moreMessages: false,
        load_more: false,
        schedule: false,
      },
      form: {
        name: '',
        description: '',
      },
      show: {
        conversation: false,
        edit_note: false,
      },
      pagination: {
        messages: 1,
      },
      max_page: {
        messages: true,
      },
      loaderStack: 0,
      loader: null,
      leads: {},
      channel: {},
      logs: [],
      schedules: [],
      conversationHistoryList: [],
      isMoreResultHistory: false,
      conversation: {},
      can_reply_message: true,
      showDetailConversation: false,
      showModalReachTicket: false,
      showModalStartTicket: false,
      messages: [],
      channelList: [
        { name: 'WhatsApp', icon: 'mdi mdi-whatsapp', value: 'whatsapp' },
        { name: 'Telegram', icon: 'mdi mdi-telegram', value: 'telegram' },
        { name: 'Email', icon: 'mdi mdi-email', value: 'email' },
        { name: 'Instagram', icon: 'mdi mdi-instagram', value: 'instagram' },
        { name: 'Webchat', icon: 'mdi mdi-forum-outline', value: 'webchat' },
        { name: 'Facebook Messenger', icon: 'mdi mdi-facebook-messenger', value: 'fb_messenger' },
        { name: 'Phone Call', icon: 'mdi mdi-forum-outline', value: 'phone_call' },
      ],
      perfectScroll: [],
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    formatted_messages() {
      return _.uniqBy(this.messages.filter((v) => v.id), 'id');
    },
  },
  mounted() {
    if (document.querySelectorAll('.chat-content .chat-body').length) {
      this.perfectScroll.push(new PerfectScrollbar('.chat-content .chat-body'));
      this.scrollChatToBottom();
    }
    this.loadDetail();
    this.loadLog();
    this.loadSchedule();
  },
  methods: {
    back() {
      this.$router.back();
    },
    isCanChange() {
      let result = false;
      const { is_pic } = this.$store.state.auth.userProfile;
      if (is_pic === true) {
        result = true;
      }
      return result;
    },
    getUser(sch) {
      let team = sch.user.name;
      if (sch.user_contributed) {
        team += ', ';
        team += sch.user_contributed.map((v) => v.name).join(', ');
      }
      return team;
    },
    toChat() {
      if (this.conversation?.id) {
        this.$router.push({
          path: '/conversations',
          query: {
            code: this.conversation.code,
            convo: this.conversation.id,
            state: this.conversation.status,
          },
        });
      } else {
        this.loadDetail();
      }
    },
    toConversation(ticket, convo_id, status) {
      if (this.conversation?.id) {
        this.$router.push({
          path: '/conversations',
          query: {
            code: ticket,
            convo: convo_id,
            state: status,
          },
        });
      } else {
        this.loadDetail();
      }
    },
    showEditNote() {
      this.show.edit_note = !this.show.edit_note;
    },
    upperCaseFirst(string) {
      return _.upperFirst(string);
    },
    identicons(value) {
      return toSvg(value, '30');
    },
    isFromAgent(message) {
      return message.type === 'out' && message.body_type !== 'system' && message.user?.name;
    },
    loadDetail() {
      this.loading.detail = true;
      if (this.isCanChange() === false) {
        this.$router.push('/leads/board');
      } else {
        leadsAPI.get_leads_detail({
          workspace_id: this.activeWorkspace._id,
          id: this.$route.params.id,
        }).then(async (response) => {
          this.loading.detail = false;
          await popupErrorMessages(response);
          this.channel = response.data.channel;
          this.leads = response.data.detail;
          this.form.name = this.leads.name;
          this.leads.participant = !_.isEmpty(this.leads.user_contributed) ? this.leads.user_contributed.map((v) => v.name).join(', ') : '-';
          this.leads.amount_str = this.leads.amount ? `Rp. ${parseInt(this.leads.amount, 0).toLocaleString()}` : '-';
          this.leads.due_date_str = this.leads.due_date ? moment(this.leads.due_date).format('DD MMM') : '-';
          this.leads.completed_at_str = this.leads.completed_at ? moment(this.leads.completed_at).format('LLL') : '';
          this.leads.status_color = 'text-muted';
          if (this.leads.status === 'lost') {
            this.leads.status_color = 'text-danger';
          } else if (this.leads.status === 'won') {
            this.leads.status_color = 'text-primary';
          }
          this.leads.label_date = '';
          this.leads.color_date = '';
          if (moment(this.leads.due_date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
            this.leads.label_date = 'Today';
            this.leads.color_date = 'warning';
          } else if (moment().format('YYYY-MM-DD') === moment(this.leads.due_date).subtract(1, 'days').format('YYYY-MM-DD')) {
            this.leads.label_date = 'Tomorrow';
            this.leads.color_date = 'warning';
          } else if (moment().format('YYYY-MM-DD') === moment(this.leads.due_date).subtract(7, 'days').format('YYYY-MM-DD')) {
            this.leads.label_date = '1 week left';
            this.leads.color_date = 'primary';
          } else if (moment(this.leads.due_date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
            this.leads.label_date = 'Overdue';
            this.leads.color_date = 'danger';
          } else if (moment().format('YYYY-MM-DD') > moment(this.leads.due_date).subtract(7, 'days').format('YYYY-MM-DD')) {
            const diffDays = moment(moment(this.leads.due_date).format('YYYY-MM-DD')).endOf('day').fromNow();
            this.leads.label_date = `${diffDays.replace('in ', '')} left`;
            this.leads.color_date = 'primary';
          }
          if (this.leads.contact.conversation?.id) {
            const conversation_id = this.leads.contact.conversation?.id;
            const parent_contact = this.leads.contact;
            this.conversation = {
              id: conversation_id,
              code: this.leads.contact.conversation?.code,
              status: this.leads.contact.conversation?.status,
              contactName: parent_contact.display_name || parent_contact.name,
              phoneNumber: parent_contact.phone_number,
              contactId: parent_contact.id,
            };
            if (parent_contact.channel === 'instagram') {
              this.conversation.phoneNumber = parent_contact.name || '';
            }
            if (parent_contact.channel === 'webchat') {
              this.conversation.phoneNumber = '';
            }
            this.show.conversation = true;
            this.showDetailChat({ conversation_id });
          } else {
            this.show.conversation = false;
          }
        });
      }
    },
    loadLog() {
      this.loading.log = true;
      leadsAPI.get_logs({
        workspace_id: this.activeWorkspace._id,
        id: this.$route.params.id,
      }).then(async (res) => {
        await popupErrorMessages(res);
        this.logs = res.data.map((v) => {
          v.description_added = `${moment(v.created_at).format('D MMM YYYY HH:mm')}`;
          return v;
        });
      });
      this.loading.log = false;
    },
    loadSchedule() {
      this.loading.schedule = true;
      leadsAPI.get_schedules_by_id(
        this.activeWorkspace._id,
        this.$route.params.id,
      ).then(async (res) => {
        await popupErrorMessages(res);
        this.schedules = res.data.map((v) => {
          v.schedule_at_str = `${moment(v.schedule_at).format('D MMM YYYY HH:mm')}`;
          return v;
        });
      });
      this.loading.schedule = false;
    },
    async showDetailChat({ conversation_id }) {
      if (this.pagination.messages === 1) {
        this.messages = [];
        this.loading.conversation = true;
      }
      await conversationsAPI.getListDetail(this.activeWorkspace._id, conversation_id, this.pagination.messages)
        .then(async (res) => {
          this.can_reply_message = res.data.can_reply_message;
          const respCheckActiveWindow = await conversationsAPI.checkActiveWindow({ workspace_id: this.activeWorkspace._id, conversation_id });
          if (respCheckActiveWindow.data) {
            this.can_reply_message = respCheckActiveWindow.data.window_active;
            if (!this.can_reply_message) { // check if has message < 24 hour
              const found = res.data.messages.find((v) => v.type === 'in' && moment(v.created_at).diff(moment(), 'hour') < 24 && moment(v.created_at).diff(moment(), 'second') > 0);
              if (found) {
                this.can_reply_message = true;
              }
            }
          }
          if (this.pagination.messages === 1) {
            this.messages = res.data.messages.map((message) => {
              const mappedMessage = message;
              return this.constructMessage(mappedMessage);
            }).reverse();
          } else if (this.pagination.messages > 1) {
            const msg = res.data.messages.map((message) => {
              const mappedMessage = message;
              return this.constructMessage(mappedMessage);
            }).reverse();
            this.messages.unshift(...msg);
          }
          if (this.messages.length >= res.data.total_messages) {
            this.max_page.messages = true;
          } else {
            this.max_page.messages = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.loading.conversation = false;
      if (this.pagination.messages === 1) {
        this.scrollChatToBottom();
      }
    },
    constructMessage(message) {
      const mappedMessage = message;
      const isToday = moment(message.updated_at).isSame(moment(), 'day');
      mappedMessage.formattedTime = moment(message.updated_at).format(isToday ? 'HH:mm' : 'D MMM YYYY HH:mm');
      mappedMessage.formattedType = message.type === 'in' ? 'friend' : 'me';
      if (message.type === 'out' && (['private_note', 'system'].includes(mappedMessage.body_type) || _.isEmpty(message.user))) {
        mappedMessage.formattedType = 'system';
        if (mappedMessage.body_type === 'private_note') {
          mappedMessage.formattedType = 'private_note';
        }
      }
      if (!message.body_type) message.body_type = 'text';
      const channel_icon = this.channelList.find((v) => v.value === message.channel);
      message.channel_icon = `mdi mdi-${channel_icon?.icon}`;
      if (message.channel === 'whatsapp') {
        message.channel_name_str = 'WhatsApp';
        message.channel_color = 'text-success';
      } else if (message.channel === 'telegram') {
        message.channel_name_str = 'Telegram';
        message.channel_color = 'text-telegram';
      } else if (message.channel === 'webchat') {
        message.channel_name_str = 'Webchat';
        message.channel_color = 'text-default';
      } else if (message.channel === 'instagram') {
        message.channel_name_str = 'Instagram';
        message.channel_color = 'text-instagram';
      } else if (message.channel === 'fb_messenger') {
        message.channel_name_str = 'Facebook';
        message.channel_color = 'text-fb_messenger';
      } else {
        message.channel_name_str = 'Webchat';
        message.channel_color = 'text-default';
      }
      mappedMessage.is_loading = false;
      return mappedMessage;
    },
    goToQuotedMessage(id, context_message) {
      const target = `message-${id}`;
      const element = document.getElementById(target);
      if (element) {
        setTimeout(() => {
          const container = document.querySelector('.chat-content .chat-body');
          const containerRect = container.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const offsetTop = elementRect.top - containerRect.top + container.scrollTop;
          container.scrollTop = offsetTop;
          container.focus();
          this.perfectScroll[1].update();
        }, 0);
      } else {
        // popup
        this.showModalPreviewQuoted = true;
        this.selectedQuotedMessage = this.constructMessage(context_message);
      }
      this.replyMessageContext = null;
    },
    async loadMoreMessages() {
      this.loading.moreMessages = true;
      this.pagination.messages += 1;
      await this.showDetailChat({
        conversation_id: this.conversation.id,
        reload_history: false,
      });
      this.loading.moreMessages = false;
    },
    async handlerShowDetailConvo(contact_id) {
      this.showDetailConversation = !this.showDetailConversation;
      this.conversationHistory(contact_id);
    },
    async conversationHistory(contact_id) {
      this.loading.history = true;
      await conversationsAPI.getConversationHistory(this.activeWorkspace._id, contact_id)
        .then(async (response) => {
          this.loading.history = false;
          await popupErrorMessages(response);
          response.data.rows = response.data.rows.map((v) => {
            const isToday = moment(v.resolved_at).isSame(moment(), 'day');
            v.formattedTime = moment(v.resolved_at).format(isToday ? 'HH:mm' : 'D MMM YYYY HH:mm');
            if (!v.thumbnail_color) v.thumbnail_color = '#192A53';
            return v;
          });
          this.conversationHistoryList.push(...response.data.rows);
          if (this.conversationHistoryList.length >= response.data.count) {
            this.isMoreResultHistory = false;
          }
        })
        .catch(() => {
          this.loading.history = false;
        });
      // this.scrollToTopHistory();
    },
    resetListConversation(closeDetail = true) {
      if (closeDetail) {
        this.conversation = {};
        this.rows_pin = {};
        this.showDetail = false;
        this.loadedConversation = false;
        this.showDetailConversation = false;
      }
      setTimeout(() => {
        this.loadConversations({ page: 1 });
      }, 500);
    },
    async infiniteHandler() {
      this.pagination.page += 1;
      this.loading.load_more = true;
      await this.loadConversations({
        page: this.pagination.page,
      });
      this.loading.load_more = false;
    },
    scrollChatToBottom() {
      setTimeout(() => {
        console.log(this.perfectScroll);
        const container = this.$el.querySelector('.chat-content .chat-body');
        container.scrollTop = container.scrollHeight;
        container.focus();
        // this.perfectScroll[0].update();
      }, 0);
    },
    scrollToTop() {
      setTimeout(() => {
        this.perfectScroll[0].update();
      }, 0);
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
  },
};
</script>
<style>
[data-initials]:before {
  background: #8CC8ED;
  color: white;
  opacity: 1;
  content: attr(data-initials);
  display: inline-block;
  font-weight: bold;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 0.5em;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
[data-initials-ticket] {
  --initial-ticket-color: #192A53;
}
[data-initials-ticket]:before {
  /* background: #192A53; */
  background-color: var(--initial-ticket-color);
  font-size: 8px;
  color: white;
  opacity: 1;
  content: attr(data-initials-ticket);
  display: inline-block;
  font-weight: bold;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 1em;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.identicons {
  vertical-align: top;
  margin-right: 1em;
}
/*quoted message*/
.message-item .content{
  position: relative;
}
.quoted-message{
  width: auto;
  display: inline-block;
  background: #c9fcc7;
  color: rgba(24,41,83,0.7);
  padding: 8px 15px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  position: relative;
  z-index: 2;
  margin-bottom: -10px;
  transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -webkit-transform: translateY(-20px);
}
.quoted-message:hover {
  color: rgba(24,41,83,0.7);
}
.quoted-reply-message{
  width: auto;
  display: inline-block;
  background: #ffffff;
  color: rgba(65, 65, 65, 0.7);
  padding: 8px 25px;
  border-radius: 4px;
  border-style: solid;
  border-color: rgba(65, 65, 65, 0.7);
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  position: relative;
  z-index: 2;
  margin-top: 5px;
  transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -webkit-transform: translateY(-20px);
}
.quoted-reply-message:hover {
  color: rgba(24,41,83,0.7);
}
.message-item.me{
  text-align: left;
}
.message.has-quote{
  margin-top: 16px;
}

/* reply area */
.input-group input.private,
.input-group textarea.private,
.nav-tabs .nav-link.active#private-tab{
  background: #FBFBF3;
}

.chat-footer .search-form .input-group{
  /* height: 45px !important; */
  position: relative;
}

/* textarea#chatForm,
textarea#privateForm{
  padding: 12px 20px 12px 30px;
  height: 40px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
} */

textarea#chatForm{
  padding: 12px 20px 12px 30px;
  height: 40px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
textarea#privateForm{
  padding: 12px 20px 12px 30px;
  height: 55px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.pr{
  position: relative;
}

.status-read{
  color: rgb(52, 183, 241);
}
.inbound-text{
  color: rgb(37, 211, 102)
}

/* Product */
.thumbnail-product {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  border-radius: 4px;
  box-sizing: border-box;
  /* width: 240px; */
}

.thumbnail-product::after {
  content: '';
  display: block;
  padding-top: calc(100% * (1 / 1.91));
}
</style>
<style lang="scss">
.button-action-system {
  margin-right: -50px !important;
  // margin-left: -20px !important;
  text-align: right !important;
}
.messages .message-item {
  &.system {
    margin: auto;
    .content {
      order: 1;
      margin: auto !important;
      // margin-left: -20px !important;
      color: #212529;
      .bubble {
        background: #e7e7e7;
        border-radius: 5px;
        margin: auto;
        align-self: center;
        p {
          text-align: center;
        }
      }
      span {
        text-align: center;
        display: block;
      }
    }
  }
}

.messages .message-item {
  &.private_note {
    margin: auto;
    .content {
      order: 1;
      margin: auto !important;
      color: #fff;
      .bubble {
        background: #fff3d5;
        color: #000;
        border-radius: 5px;
        margin: auto;
        align-self: center;
        p {
          text-align: center;
        }
      }
      span {
        text-align: center;
        display: block;
      }
    }
  }
}
ul .message-action {
  display: flex;
  list-style: none;
  padding: 0;
}

.callout {
  padding: 10px;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 5px;
  background:rgba(24,41,83,0.1);
  h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  code {
    border-radius: 3px;
  }
  & + .bs-callout {
    margin-top: -5px;
  }
}
@each $name,$color in (default,#777),(primary,#428bca),(success,#5cb85c),(danger,#d9534f),(warning,#f0ad4e),(info,#5bc0de), (bdc,#29527a) {
  .callout-#{$name} {
    border-left-color: $color;
    h4 {
      color: $color;
    }
  }
}
</style>
